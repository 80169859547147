/* src/ComingSoon.css */

.coming-soon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #000000;
    text-align: center;
  }
  
  .logo {
    max-width: 200px;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 3em;
    color: #32aae6;
  }
  
  p {
    font-size: 1.2em;
    color: #cfcfcf;
  }